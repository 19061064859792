/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldModule } from 'app/components/form/form-field/form-field.module';
import { InputFieldModule } from 'app/components/form/input-field/input-field.module';
import { DevLoginComponent } from 'app/pages/dev-login/dev-login.component';
import { DevLoginService } from 'app/services/dev-login/dev-login.service';
import { DevLoginRoutingModule } from './dev-login.routing.module';

@NgModule({
	declarations: [DevLoginComponent],
	imports: [
		CommonModule,
		DevLoginRoutingModule,
		FormFieldModule,
		InputFieldModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule,
		TranslateModule,
		MatButtonModule,
	],
	providers: [DevLoginService, provideHttpClient(withInterceptorsFromDi())],
})
export class DevLoginModule {}
